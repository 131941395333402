import { useState, useCallback } from "react";

export const useFormValidation = (schema) => {
  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = useCallback(
    (formData, relationships) => {
      const errors = {};
      let totalMissingFields = 0;

      const isFieldEmpty = (value) => {
        if (value === 0 || value === false) return false;
        return (
          value === undefined ||
          value === null ||
          value === "" ||
          (Array.isArray(value) && value.length === 0)
        );
      };

      // Handle direct fields
      const validateDirectFields = () => {
        const directErrors = {};

        if (schema.required) {
          console.log("Form Data", formData);
          schema.required.forEach((field) => {
            if (isFieldEmpty(formData[field])) {
              console.log("field", field);
              directErrors[field] = {
                message: "This field is required",
                severity: "error",
              };
              totalMissingFields++;
            }
          });
        }

        if (Object.keys(directErrors).length > 0) {
          errors._direct = directErrors;
        }
      };

      // Check if schema has direct fields
    const hasDirectFields = Object.values(schema.properties).some(
      prop => prop.type !== 'object' && prop.type !== 'array'
    );

    if (hasDirectFields) {
      validateDirectFields();
    } else {

      Object.entries(schema.properties).forEach(
        ([sectionKey, sectionSchema]) => {
          if (sectionSchema.type === "array") {
            const sectionData = formData[sectionKey] || [];

            // Check if array section has minimum items requirement
            if (
              sectionSchema.minItems &&
              sectionData.length < sectionSchema.minItems
            ) {
              errors[sectionKey] = {
                _section: {
                  message: `At least ${sectionSchema.minItems} item(s) required`,
                  severity: "error",
                },
              };
              totalMissingFields++;
            }

            sectionData.forEach((item, index) => {
              const fieldErrors = {};

              // Check required fields in array items
              if (sectionSchema.items?.required) {
                sectionSchema.items.required.forEach((field) => {
                  // Enhanced check for empty values
                  const value = item[field];
                  const isEmpty = isFieldEmpty(value);

                  if (isEmpty) {
                    fieldErrors[field] = {
                      message: "This field is required",
                      severity: "error",
                    };
                    totalMissingFields++;
                  }
                });
              }

              // Check parent relationship if required
              if (
                sectionSchema.items?.dependencies?.parent &&
                !relationships[`${sectionKey}-${index}`]
              ) {
                fieldErrors._relationship = {
                  message: "Association is required",
                  severity: "error",
                };
                totalMissingFields++;
              }

              if (Object.keys(fieldErrors).length > 0) {
                errors[`${sectionKey}-${index}`] = fieldErrors;
              }
            });
          } else if (sectionSchema.type === "object") {
            const sectionData = formData[sectionKey] || {};
            const fieldErrors = {};

            sectionSchema.required?.forEach((field) => {
              // Only mark as error if the field is truly empty
              //const value = sectionData[field];
              //if (isFieldEmpty(value) && value !== 0) {
              if (!sectionData[field] && sectionData[field] !== 0) {
                fieldErrors[field] = {
                  message: "This field is required",
                  severity: "error",
                };
                totalMissingFields++;
              }
            });

            if (Object.keys(fieldErrors).length > 0) {
              errors[sectionKey] = fieldErrors;
            }
          }
        }
      );
    }

      setValidationErrors(errors);
      return { errors, totalMissingFields };
    },
    [schema]
  );

  const hasErrors = useCallback((validationResult) => {
    return validationResult.totalMissingFields > 0;
  }, []);

  return {
    validationErrors,
    validateForm,
    hasErrors,
    setValidationErrors,
  };
};
