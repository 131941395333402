import { useState, useEffect, useCallback } from "react";
import { message } from "antd";
import { getProducts } from "../../services/productService";
import { getBrands } from "../../services/brandService";
import { getSuppliers } from "../../services/supplierService";

export function useManageProducts() {
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [metaProductDataIds, setMetaProductDataIds] = useState([]);
  const [metaKpis, setMetaKpis] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [getKpis, setKpis] = useState(true);
  const [getProductDataIds, setProductDataIds] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [loading, setLoading] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Some optional filters
  const [brandId, setBrandId] = useState("");

  // Sorting
  const [sortBy, setSortBy] = useState(null); // e.g. "name", "status"
  const [sortDirection, setSortDirection] = useState(null); // "asc" or "desc"

  const [isFetching, setIsFetching] = useState(false);

  const [filters, setFilters] = useState({
    brand_name: [],
    supplier_name: [],
    product_data_statuses: [],
    status: [],
  });

  useEffect(() => {
    // initial fetch for brands, suppliers
    fetchMetaData();
  }, []);

  // Whenever pagination or sorting changes, refetch
  useEffect(() => {
    fetchProducts();
  }, [currentPage, pageSize, sortBy, sortDirection, filters, searchValue]);

  const fetchMetaData = async () => {
    try {
      setLoading(true);
      await Promise.all([fetchBrands(), fetchSuppliers(), fetchProducts()]);
    } finally {
      setLoading(false);
    }
  };

  // MAIN function that fetches the *current page* of products from the server
  const fetchProducts = useCallback(async () => {
    if (isFetching) return;

    setLoading(true);
    try {
      // Build query parameters

      const queryParams = {
        page: currentPage,
        per_page: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        search: searchValue,
        filters: {
          brands: filters.brand_name,
          suppliers: filters.supplier_name,
          product_data_statuses: filters.product_data_statuses,
          statuses: filters.status,
        },
      };

      // Add to query params if kepi and product data ids are needed
      if (getKpis) {
        queryParams.include_kpis = getKpis;
      }

      if (getProductDataIds) {
        queryParams.include_product_data_ids = getProductDataIds;
      }

      const response = await getProducts(queryParams);

      const productsData = response.data.data.data;
      const meta = response.data.meta || {};

      setProducts(productsData);

      if (meta.count) {
        setTotalItems(meta.count);
      }
      if (meta.kpis) {
        setMetaKpis(meta.kpis);
      }

      if (meta.product_data_ids) {
        setMetaProductDataIds(meta.product_data_ids);
      }
    } catch (err) {
      message.error("Failed to fetch products.");
    } finally {
      setLoading(false);
    }
  }, [currentPage, pageSize, sortBy, sortDirection, filters, searchValue]);

  // brand service
  const fetchBrands = async () => {
    try {
      const res = await getBrands();
      setBrands(res.data.data);
    } catch (error) {
      message.error("Failed to fetch brands.");
    }
  };

  // supplier service
  const fetchSuppliers = async () => {
    try {
      const res = await getSuppliers();
      setSuppliers(res.data.data);
    } catch (error) {
      message.error("Failed to fetch suppliers.");
    }
  };

  return {
    // main data
    products,
    brands,
    suppliers,
    //productDataMap,
    loading,
    totalItems,
    metaProductDataIds,
    setProductDataIds,

    // search
    searchValue,
    setSearchValue,

    // pagination & filters
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,

    brandId,
    setBrandId,

    // fetch & refresh
    fetchProducts,
    setFilters,
    filters,
    metaKpis,
  };
}
