import React, { useState, useEffect, useMemo, useContext } from "react";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import KPIDashboard from "../../components/ui/kpis/KPIDashboard";
import { useNavigate } from "react-router-dom";
import { message, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import {
  FaBoxOpen,
  FaChartPie,
  FaChartBar,
  FaArchive,
  FaExclamationTriangle,
} from "react-icons/fa";

import { ClipLoader } from "react-spinners";
import { useSupplierProducts } from "../../hooks/products/useSupplierProducts";

import StatusBadge from "../../components/ui/badges/StatusBadge";

// Define status priority: Refused > To Complete > In Progress > Submitted > Archived
const STATUS_PRIORITY = [
  "refused",
  "to_complete",
  "in_progress",
  "forced_submitted",
  "submitted",
  "accepted",
];

const SupplierProductList = () => {
  const {
    products,
    includeddata,
    loading,
    fetchProducts, // Use the fetch function from the hook
    filters,
    setFilters,
    totalItems,
    setCurrentPage,
    setPageSize,
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
    metaKpis,
    searchValue,
    setSearchValue,
  } = useSupplierProducts();

  // Search state
  const navigate = useNavigate();

  // Handle view details action
  const handleViewDetails = (productId) => {
    // Get path from current URL and navigate to product detail page
    const currentPath = window.location.pathname;
    navigate(`${currentPath}/${productId}`);
  };

  /**
   * Determines the least advanced status from an array of statuses.
   * @param {Array<string>} statuses - Array of status strings.
   * @returns {string} - The least advanced status.
   */
  const getLeastAdvancedStatus = (statuses) => {
    if (!statuses || statuses.length === 0) return "N/A";

    // Normalize statuses by mapping 'forced_submitted' to 'submitted'
    const normalizedStatuses = statuses.map((status) =>
      status.toLowerCase() === "forced_submitted"
        ? "submitted"
        : status.toLowerCase()
    );

    for (let status of STATUS_PRIORITY) {
      if (normalizedStatuses.includes(status)) {
        return status;
      }
    }
    return "N/A";
  };

  // Define DataGrid columns
  const productColumns = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Référence",
      dataIndex: "reference_supplier",
      key: "reference_supplier",
      sorter: (a, b) =>
        a.attributes.reference_supplier.localeCompare(
          b.attributes.reference_supplier
        ),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: (a, b) => a.brand_name.localeCompare(b.brand_name),
    },
    {
      title: "Product Data Status",
      key: "product_data_status",
      dataIndex: "product_data_status",
      defaultSortOrder: "ascend", // Sorts based on the defined priority
      filters: [
        { text: "To complete", value: "to_complete" },
        { text: "In Progress", value: "in_progress" },
        { text: "Refused", value: "refused" },
        { text: "Submitted", value: "submitted" },
        { text: "Forced submitted", value: "forced_submitted" },
        { text: "Accepted", value: "accepted" },
      ],
      onFilter: (value, record) =>
        record.product_data_status.toLowerCase() === value,
      sorter: (a, b) => {
        const statusA = STATUS_PRIORITY.indexOf(
          a.product_data_status.toLowerCase()
        );
        const statusB = STATUS_PRIORITY.indexOf(
          b.product_data_status.toLowerCase()
        );
        return statusA - statusB;
      },
      render: (status) => (
        <>
          <StatusBadge status={status} key={status} />
        </>
      ),
      filteredValue: filters?.product_data_statuses || null,
    },
    {
      title: "Published At",
      dataIndex: "published_at",
      key: "published_at",
      sorter: (a, b) => new Date(a.published_at) - new Date(b.published_at),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => handleViewDetails(record.id)}
          aria-label={`View details of ${record.attributes.name}`}
        >
          View
        </Button>
      ),
    },
  ];

  // Calculate KPIs
  const totalProducts = metaKpis?.total_products;
  const calculateKPIs = useMemo(() => {
    let totalInProgress = 0;
    let totalSubmitted = 0;
    let totalAccepted = 0;
    let totalArchived = metaKpis.product_status?.archived;
    let refusedProducts = 0;

    const aggregated = {}; // ex: { in_progress: 50, to_complete: 2636, forced_submitted: 1, ... }
    const productDataStatus = metaKpis?.product_data_status || {};

    for (const statusesObj of Object.values(productDataStatus)) {
      // statusesObj could be: { in_progress: 24, to_complete: 1312 }
      for (const [status, count] of Object.entries(statusesObj)) {
        aggregated[status] = (aggregated[status] || 0) + count;
      }
    }

    totalInProgress = aggregated["in_progress"] || 0;
    totalSubmitted =
      (aggregated["forced_submitted"] || 0) + (aggregated["submitted"] || 0);
    totalAccepted = aggregated["accepted"] || 0;
    refusedProducts = aggregated["refused"] || 0;

    return {
      totalInProgress,
      totalSubmitted,
      totalArchived,
      refusedProducts,
      totalAccepted,
    };
  }, [products, metaKpis]);

  // Define KPI configurations
  const productKPIs = [
    {
      title: "Total Products",
      value: totalProducts,
      icon: <FaBoxOpen className="h-6 w-6 text-indigo-400" />,
      color: "border-l-4 border-indigo-500",
    },
    {
      title: "In Progress requests",
      value: calculateKPIs.totalInProgress,
      icon: <FaChartBar className="h-6 w-6 text-blue-400" />,
      color: "border-l-4 border-blue-500",
    },
    {
      title: "Refused requests",
      value: calculateKPIs.refusedProducts,
      icon: <FaExclamationTriangle className="h-6 w-6 text-red-400" />,
      color: "border-l-4 border-red-500",
    },
    {
      title: "Submitted requests",
      value: calculateKPIs.totalSubmitted,
      icon: <FaChartPie className="h-6 w-6 text-purple-400" />,
      color: "border-l-4 border-purple-500",
    },
    {
      title: "Accepted requests",
      value: calculateKPIs.totalAccepted,
      icon: <FaArchive className="h-6 w-6 text-green-400" />,
      color: "border-l-4 border-green-500",
    },
  ];

  // Prepare DataGrid data
  const dataSource = useMemo(() => {
    return products.map((product) => {
      const statuses = product.attributes.product_data_status || [];
      const leastStatus = getLeastAdvancedStatus(statuses);
      return {
        key: product.id,
        id: product.id,
        name: product.attributes.name,
        attributes: product.attributes,
        reference_supplier: product.attributes.reference_supplier,
        brand_name: product.attributes.brand_name,
        product_data_status: leastStatus,
        published_at: product.attributes.created_at
          ? new Date(product.attributes.created_at).toLocaleString()
          : "N/A",
      };
    });
  }, [products]);

  const handleSort = (field, direction) => {
    if (loading) return; // prevent multiple calls while loading
    setSortBy(field);
    setSortDirection(direction);
  };

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">All Products</h2>
      </div>

      {/* KPI Dashboard */}
      <KPIDashboard kpis={productKPIs} />

      {/* DataGrid and Loading State */}
      {loading ? (
        <div className="flex justify-center items-center text-gray-500">
          <ClipLoader size={50} />
        </div>
      ) : (
        <>
          <DataGrid
            columns={productColumns}
            serverSide={true}
            data={dataSource}
            onRowClick={null}
            totalItems={totalItems}
            onChangePage={(page) => setCurrentPage(page)}
            onChangePageSize={(size) => setPageSize(size)}
            onChangeSort={handleSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onChangeFilter={(filters) => setFilters(filters)}
            onSearch={setSearchValue}
            searchValue={searchValue}
            tableFilters={filters}
          />
        </>
      )}
    </div>
  );
};

export default SupplierProductList;
