import api from "./api";

export const validateData = (data,form_type) => {
  return api
    .post("/validations", {
      validation: {
        form_type: form_type,
        form_data: data,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error("Failed to validate data");
    });
};
