import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { message } from "antd";
import {
  getSupplierProductsFlatten,
  getSupplierProducts,
} from "../../services/supplierService";
import { AuthContext } from "../../contexts/AuthContext";

export function useSupplierProducts({ flattened = false } = {}) {
  const { user } = useContext(AuthContext);
  const supplierIdRef = useRef(null);
  const abortControllerRef = useRef(null);

  const [products, setProducts] = useState([]);
  const [metaKpis, setMetaKpis] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [getKpis, setKpis] = useState(true);
  const [includeddata, setIncludedData] = useState([]);
  const [includeProductData, setIncludeProductData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const actionableStatuses = ["to_complete", "in_progress", "refused"];
  const [searchValue, setSearchValue] = useState("");

  const [filters, setFilters] = useState({
    product_data_statuses: [],
    status: [],
  });

  // Store supplier_id when user changes
  useEffect(() => {
    if (user?.supplier_id) {
      supplierIdRef.current = user.supplier_id;
    }
  }, [user]);

  // Main fetch function
  const fetchProducts = useCallback(
    async (overrideSupplierId = null) => {
      // Cancel any ongoing requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      // Use provided supplierId, or stored supplierId, or user's supplier_id
      const supplierId =
        overrideSupplierId || supplierIdRef.current || user?.supplier_id;

      if (!supplierId) {
        console.error("No supplier ID available");
        setError("No supplier ID available");
        message.error("Unable to fetch products: No supplier ID available");
        return;
      }

      if (isFetching) {
        return;
      }

      setLoading(true);
      setIsFetching(true);
      setError(null);

      try {
        const queryParams = {
          page: currentPage,
          per_page: pageSize,
          sort_by: sortBy,
          search: searchValue,
          sort_direction: sortDirection,
        };

        if (flattened) {
          queryParams.flattened = true;
        }

        if (getKpis) {
          queryParams.include_kpis = getKpis;
        }

        let response;
        let productsData;

        //console.log("filters", filters);

        if (flattened) {
          queryParams.filters = {
            product_data_statuses: filters.product_data_status?.length
              ? filters.product_data_status
              : actionableStatuses,
          };
          response = await getSupplierProductsFlatten(supplierId, queryParams, {
            signal: abortControllerRef.current.signal,
          });
          if (!response?.data?.data) {
            throw new Error("Invalid response format");
          }

          productsData = response.data.data; // array of flattened rows
        } else {
          queryParams.filters = {
            product_data_statuses: filters.product_data_status,
          };
          response = await getSupplierProducts(supplierId, queryParams, {
            signal: abortControllerRef.current.signal,
          });
          if (!response?.data?.data?.data) {
            throw new Error("Invalid response format");
          }

          productsData = response.data.data.data;
        }

        const meta = response.data.meta || {};

        setProducts(productsData);
        setTotalItems(meta.count || 0);
        setMetaKpis(meta.kpis || []);
        //setIncludedData(included);

        return productsData;
      } catch (err) {
        if (err.name === "AbortError") {
          // Request was aborted, ignore error
          return;
        }
        console.error("Error fetching products:", err);
        setError(err.message || "Failed to fetch products");
        message.error("Failed to fetch products. Please try again.");
        throw err;
      } finally {
        setLoading(false);
        setIsFetching(false);
      }
    },
    [
      currentPage,
      pageSize,
      sortBy,
      sortDirection,
      filters,
      getKpis,
      includeProductData,
      user?.supplier_id,
      searchValue,
      flattened,
    ]
  );

  // Combined effect for fetching data
  useEffect(() => {
    const supplierId = supplierIdRef.current || user?.supplier_id;
    if (supplierId) {
      fetchProducts(supplierId);
    }

    // Cleanup function to abort any pending requests
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [fetchProducts]);

  return {
    // Data
    products,
    setProducts,
    includeddata,
    loading,
    error,
    totalItems,
    metaKpis,

    // State setters
    setLoading,
    setIncludeProductData,
    setIsFetching,

    searchValue,
    setSearchValue,

    // Pagination
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,

    // Sorting
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,

    // Filters
    filters,
    setFilters,

    // Main fetch function
    fetchProducts,
  };
}
