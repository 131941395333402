import {
  FaBoxOpen,
  FaChartBar,
  FaChartPie,
  FaFileAlt,
  FaSpinner,
  FaCheckCircle,
  FaPaperPlane,
} from "react-icons/fa";

export function getProductKpis({ metaKpis }) {

  const kpis = metaKpis || {};

  // Product KPIs
  const total = kpis.total_products || 0;
  const published = kpis.product_status?.active || 0;
  const draft = kpis.product_status?.draft || 0;
  const archived = kpis.product_status?.archived || 0;

  // Product Data KPIs
  const productDataStatus = kpis.product_data_status || {};

  // 2) Aggregate all statuses into a single object
  const aggregated = {}; // ex: { in_progress: 50, to_complete: 2636, forced_submitted: 1, ... }

  for (const statusesObj of Object.values(productDataStatus)) {
    // statusesObj could be: { in_progress: 24, to_complete: 1312 }
    for (const [status, count] of Object.entries(statusesObj)) {
      aggregated[status] = (aggregated[status] || 0) + count;
    }
  }

  // 3) Summarize the aggregated counts
  const totalData = Object.values(aggregated).reduce((sum, x) => sum + x, 0);
  const inProgress = aggregated["in_progress"] || 0;
  const completed = aggregated["accepted"] || 0;
  const submitted =
    (aggregated["forced_submitted"] || 0) +
    (aggregated["submitted"] || 0) +
    (aggregated["accepted"] || 0);

  return [
    {
      title: "Total Products",
      value: total,
      icon: <FaBoxOpen className="h-6 w-6 text-indigo-400" />,
      color: "border-l-4 border-indigo-500",
    },
    {
      title: "Active Products",
      value: published,
      icon: <FaChartBar className="h-6 w-6 text-green-400" />,
      color: "border-l-4 border-green-500",
    },
    {
      title: "Draft Products",
      value: draft,
      icon: <FaChartPie className="h-6 w-6 text-yellow-400" />,
      color: "border-l-4 border-yellow-500",
    },
    {
      title: "Archived Products",
      value: archived,
      icon: <FaBoxOpen className="h-6 w-6 text-grey-400" />,
      color: "border-l-4 border-grey-500",
    },
    {
      title: "Product Data Requests",
      value: totalData,
      icon: <FaFileAlt className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-gray-500",
    },
    {
      title: "Completed Data",
      value: completed,
      icon: <FaCheckCircle className="h-6 w-6 text-green-400" />,
      color: "border-l-4 border-green-500",
    },
    {
      title: "In Progress Data",
      value: inProgress,
      icon: <FaSpinner className="h-6 w-6 text-blue-400" />,
      color: "border-l-4 border-blue-500",
    },
    {
      title: "Submitted Data (to be reviewed)",
      value: submitted,
      icon: <FaPaperPlane className="h-6 w-6 text-purple-400" />,
      color: "border-l-4 border-purple-500",
    },
  ];
}
