// hooks/useFormData.js
import { useState, useCallback, useEffect } from "react";
import {
  findDependentSections,
  getDependencyChain,
  evaluateTemplate,
} from "../utils/schemaUtils";

import { Modal } from "antd";

export const useFormData = (
  schema,
  initialData = null,
  onChanges = null,
  topLevelInfo = {}
) => {
  const [formData, setFormData] = useState({});
  const [relationships, setRelationships] = useState({});

  const createEmptySection = useCallback((sectionSchema) => {
    if (!sectionSchema?.properties) return {};

    const emptySection = {};
    Object.keys(sectionSchema.properties).forEach((key) => {
      const property = sectionSchema.properties[key];

      // Check for default value first
      if (property.default !== undefined) {
        emptySection[key] = property.default;
        return;
      }

      switch (property.type) {
        case "string":
          emptySection[key] = "";
          break;
        case "number":
          emptySection[key] = undefined;
          break;
        case "boolean":
          emptySection[key] = "No";
          break;
        case "array":
          emptySection[key] = [];
          break;
        case "object":
          emptySection[key] = createEmptySection(property);
          break;
        default:
          emptySection[key] = null;
      }
    });
    return emptySection;
  }, []);

  const createEmptyData = useCallback((schema) => {
    if (!schema.properties) return {};

    const emptyData = {};
    Object.entries(schema.properties).forEach(([key, property]) => {
      // Check if this is a direct field
      if (property.type !== "object" && property.type !== "array") {
        // Handle direct fields
        if (property.default !== undefined) {
          emptyData[key] = property.default;
        } else {
          switch (property.type) {
            case "string":
              emptyData[key] = "";
              break;
            case "number":
              emptyData[key] = undefined;
              break;
            case "boolean":
              emptyData[key] = false;
              break;
            default:
              emptyData[key] = null;
          }
        }
      } else if (property.type === "array") {
        // Handle array sections
        emptyData[key] =
          property.minItems > 0
            ? Array(property.minItems)
                .fill(null)
                .map(() => createEmptySection(property.items))
            : [];
      } else {
        // Handle object sections
        emptyData[key] = createEmptySection(property);
      }
    });
    return emptyData;
  }, []);

  // Initialize form with default sections
  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      const mergedData = {
        ...createEmptyData(schema), // Ensure all fields exist
        ...initialData,
        ...topLevelInfo,
      };

      // If initialData had fields under "", move them to top level
      if (mergedData[""] && typeof mergedData[""] === "object") {
        Object.entries(mergedData[""]).forEach(([key, value]) => {
          mergedData[key] = value;
        });
        delete mergedData[""];
      }
      //console.log("Merged Data", mergedData);
      // Existing logic for initial data
      setFormData(mergedData);

      // Set up relationships from parentId fields
      const newRelationships = {};
      Object.entries(initialData).forEach(([sectionKey, sectionData]) => {
        if (Array.isArray(sectionData)) {
          sectionData.forEach((item, index) => {
            if (item.parentId) {
              newRelationships[`${sectionKey}-${index}`] = item.parentId;
            }
          });
        }
      });
      setRelationships(newRelationships);
    } else {
      // Initialize with empty data
      const emptyData = {};
      const initialRelationships = {};

      // First pass: Initialize all sections
      Object.entries(schema.properties).forEach(
        ([sectionKey, sectionSchema]) => {
          if (sectionSchema.type === "array" && sectionSchema.minItems > 0) {
            emptyData[sectionKey] = Array(sectionSchema.minItems)
              .fill(null)
              .map(() => createEmptySection(sectionSchema.items));
          } else if (sectionSchema.type === "object") {
            emptyData[sectionKey] = createEmptySection(sectionSchema);
          }
        }
      );

      // Second pass: Set up dependencies
      Object.entries(schema.properties).forEach(
        ([sectionKey, sectionSchema]) => {
          if (
            sectionSchema.type === "array" &&
            sectionSchema.items?.dependencies?.parent &&
            sectionSchema.minItems > 0
          ) {
            const { parentSection } = sectionSchema.items.dependencies.parent;

            // For each required item in this section
            for (let i = 0; i < sectionSchema.minItems; i++) {
              // Associate with the first parent by default
              initialRelationships[`${sectionKey}-${i}`] = `${parentSection}-0`;

              // Also set the parentId in the formData
              if (emptyData[sectionKey]?.[i]) {
                emptyData[sectionKey][i].parentId = `${parentSection}-0`;
              }
            }
          }
        }
      );

      setFormData(emptyData);
      setRelationships(initialRelationships);
    }
  }, [schema, initialData, createEmptySection]);

  const getParentSectionInfo = useCallback(
    (sectionKey) => {
      const sectionSchema = schema.properties[sectionKey];
      if (!sectionSchema?.items?.dependencies?.parent) return null;

      const { parentSection, labelTemplate } =
        sectionSchema.items.dependencies.parent;
      return {
        parentSection,
        labelTemplate,
      };
    },
    [schema]
  );

  const handleFieldChange = useCallback(
    (sectionKey, index, fieldKey, value) => {
      setFormData((prev) => {
        const newData = { ...prev };

        // Handle direct fields
        if (!sectionKey) {
          newData[fieldKey] = value;
          return newData;
        }
        const isArraySection = Array.isArray(prev[sectionKey]);

        if (isArraySection && typeof index === "number") {
          const newArray = [...prev[sectionKey]];
          newArray[index] = {
            ...newArray[index],
            [fieldKey]: value,
          };

          // Update relationships if the fieldKey is 'parentId'
          if (fieldKey === "parentId") {
            setRelationships((prevRel) => ({
              ...prevRel,
              [`${sectionKey}-${index}`]: value,
            }));
          }

          return {
            ...prev,
            [sectionKey]: newArray,
          };
        } else {
          return {
            ...prev,
            [sectionKey]: {
              ...(prev[sectionKey] || {}),
              [fieldKey]: value,
            },
          };
        }
      });

      // TODO: Clear validation error for the field with setValidationErrors

      if (onChanges) {
        onChanges();
      }
    },
    [setRelationships]
  );

  const handleAddSection = useCallback(
    (sectionKey, parentId = null, initialData = null) => {
      const sectionSchema = schema.properties[sectionKey];
      if (!sectionSchema) return null;

      const parentInfo = getParentSectionInfo(sectionKey);

      if (parentInfo && !parentId) {
        return {
          needsAssociation: true,
          sectionKey,
          data: initialData || createEmptySection(sectionSchema.items),
          parentSection: parentInfo.parentSection,
        };
      }

      // If we have parentId or don't need one, add the section
      setFormData((prev) => {
        const newArray = [
          ...(prev[sectionKey] || []),
          initialData || createEmptySection(sectionSchema.items),
        ];

        if (parentId) {
          // Set parentId in the item's data
          const newIndex = newArray.length - 1;
          newArray[newIndex].parentId = parentId;
          setRelationships((prevRel) => ({
            ...prevRel,
            [`${sectionKey}-${newIndex}`]: parentId,
          }));
        }

        return {
          ...prev,
          [sectionKey]: newArray,
        };
      });

      return { needsAssociation: false };
    },
    [schema, createEmptySection, getParentSectionInfo]
  );

  const handleRemoveSection = useCallback(
    (sectionKey, index) => {
      // Check for dependent sections
      const dependentSections = Object.entries(relationships)
        .filter(([_, parentId]) => parentId === `${sectionKey}-${index}`)
        .map(([key]) => key.split("-")[0]);

      if (dependentSections.length > 0) {
        Modal.confirm({
          title: "Confirm Delete",
          content: `This will also delete the following associated items: ${dependentSections.join(
            ", "
          )}. Are you sure?`,
          onOk: () => {
            setFormData((prev) => {
              const newData = { ...prev };
              newData[sectionKey] = newData[sectionKey].filter(
                (_, i) => i !== index
              );

              // Remove dependent sections
              dependentSections.forEach((depSection) => {
                if (newData[depSection]) {
                  newData[depSection] = newData[depSection].filter(
                    (_, i) =>
                      relationships[`${depSection}-${i}`] !==
                      `${sectionKey}-${index}`
                  );
                }
              });

              return newData;
            });

            setRelationships((prev) => {
              const newRel = { ...prev };
              delete newRel[`${sectionKey}-${index}`];
              Object.keys(newRel).forEach((key) => {
                if (newRel[key] === `${sectionKey}-${index}`) {
                  delete newRel[key];
                }
              });
              return newRel;
            });
          },
        });
      } else {
        setFormData((prev) => ({
          ...prev,
          [sectionKey]: prev[sectionKey].filter((_, i) => i !== index),
        }));
        setRelationships((prev) => {
          const newRel = { ...prev };
          delete newRel[`${sectionKey}-${index}`];
          return newRel;
        });
      }
    },
    [relationships]
  );

  const handleDuplicateSection = useCallback(
    (sectionKey, index) => {
      const sectionData = formData[sectionKey][index];
      const parentId = relationships[`${sectionKey}-${index}`];
      const duplicatedData = JSON.parse(JSON.stringify(sectionData));
      handleAddSection(sectionKey, parentId, duplicatedData);
    },
    [formData, relationships, handleAddSection]
  );

  const setInitialFormData = useCallback(
    (data, topLevelInfoMap = {}, uiSchema = {}) => {
      if (!data) return;

      // Clone the data to avoid reference issues
      const initialData = JSON.parse(JSON.stringify(data));

      // Ensure all schema sections exist in the data
      Object.entries(schema.properties).forEach(
        ([sectionKey, sectionSchema]) => {
          if (!(sectionKey in initialData)) {
            if (sectionSchema.type === "array") {
              initialData[sectionKey] = [];
            } else if (sectionSchema.type === "object") {
              initialData[sectionKey] = createEmptySection(sectionSchema);
            }
          }
        }
      );

      // Extract relationships from array sections
      const newRelationships = {};
      Object.entries(initialData).forEach(([sectionKey, sectionData]) => {
        if (Array.isArray(sectionData)) {
          sectionData.forEach((item, index) => {
            if (item.parentId) {
              console.log("Adding relationship:", `${sectionKey}-${index}`);
              newRelationships[`${sectionKey}-${index}`] = item.parentId;
            } else if (item.parentId === null) {
              console.log("Null parentId found:", `${sectionKey}-${index}`);
            }
          });
        }
      });

      setFormData(initialData);
      setRelationships(newRelationships);
    },
    [schema, createEmptySection]
  );

  return {
    formData,
    relationships,
    handleFieldChange,
    handleAddSection,
    handleRemoveSection,
    handleDuplicateSection,
    setInitialFormData,
  };
};
