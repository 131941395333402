// src/components/AdminDashboard.js
import React, { useEffect, useState } from "react";
import { getBrands } from "../../services/brandService";
import { getSuppliers } from "../../services/supplierService";
import { getProducts } from "../../services/productService";
import KPI from "../../components/ui/kpis/KPI";
import {
  FaBuilding,
  FaTruck,
  FaBox,
  FaCheckCircle,
  FaUserTimes,
  FaChartLine,
  FaChartPie,
  FaChartBar,
  FaSpinner,
} from "react-icons/fa";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  Legend,
} from "recharts";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminDashboard = () => {
  const [brandsCount, setBrandsCount] = useState(0);
  const [suppliersCount, setSuppliersCount] = useState(0);
  const [productsCount, setProductsCount] = useState(0);
  const [completedProductsPercentage, setCompletedProductsPercentage] =
    useState(0);
  const [invalidSuppliersCount, setInvalidSuppliersCount] = useState(0);

  // Data for charts
  const [productsOverTime, setProductsOverTime] = useState([]);
  const [supplierStatus, setSupplierStatus] = useState([]);
  const [supplierDistribution, setSupplierDistribution] = useState([]);
  const [productStatusData, setProductStatusData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      // Fetch brands
      const brandsResponse = await getBrands();
      setBrandsCount(brandsResponse.data.data.length);

      // Fetch suppliers
      const suppliersResponse = await getSuppliers();
      const suppliers = suppliersResponse.data.data;
      setSuppliersCount(suppliers.length);

      // Fetch products
      const productsResponse = await getProducts({ include_kpis: true });
      const products = productsResponse.data.data.data;
      const metaDataCount = productsResponse.data.meta.count;
      const metaKpis = productsResponse.data.meta.kpis;
      setProductsCount(metaDataCount);

      // Extract included product_datum
      const included = productsResponse.data.included || [];

      // Calculate completed products percentage based on product_datum statuses
      let totalProductData = 0;
      let completedProductData = 0;


      Object.keys(metaKpis.product_data_status).forEach((key) => {
        const status = metaKpis.product_data_status[key];
        Object.keys(status).forEach((s) => {
          totalProductData += status[s];
          if (s === "accepted" || s === "refused") {
            completedProductData += status[s];
          }
        });
      });

      const completedPercentage =
        totalProductData > 0
          ? (completedProductData / totalProductData) * 100
          : 0;

      setCompletedProductsPercentage(completedPercentage.toFixed(2));

      // Fetch invalid suppliers (e.g., suppliers who haven't accepted the invite)
      const invalidSuppliers = suppliers.filter(
        (supplier) => supplier.attributes.status !== "active"
      );
      setInvalidSuppliersCount(invalidSuppliers.length);

      const productsOverTimeData = metaKpis.products_over_time.map((item) => ({
        month: item.date,
        products: item.count,
      }));

      console.log("Products Over Time", productsOverTimeData);

      setProductsOverTime(productsOverTimeData);

      // Prepare data for Supplier Status Distribution
      const activeSuppliers = suppliers.filter(
        (supplier) => supplier.attributes.is_active
      );

      const statusCounts = suppliers.reduce((acc, supplier) => {
        const status = supplier.attributes.status || "Unknown";
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, {});

      const supplierStatusData = Object.keys(statusCounts).map((status) => ({
        name: status.charAt(0).toUpperCase() + status.slice(1),
        value: statusCounts[status],
      }));

      setSupplierStatus(supplierStatusData);

      // Prepare data for Supplier Distribution by Country
      const suppliersByCountry = {};
      suppliers.forEach((supplier) => {
        let country = supplier.attributes.country || "Unknown";
        country = country.charAt(0).toUpperCase() + country.slice(1);
        suppliersByCountry[country] = (suppliersByCountry[country] || 0) + 1;
      });

      const supplierDistributionData = Object.keys(suppliersByCountry).map(
        (country) => ({
          country,
          suppliers: suppliersByCountry[country],
        })
      );

      setSupplierDistribution(supplierDistributionData);

      // Prepare data for Product Data Status by Type

      const productStatusCounts = {};

      Object.keys(metaKpis.product_data_status).forEach((key) => {
        const status = metaKpis.product_data_status[key];
        Object.keys(status).forEach((s) => {
          productStatusCounts[s] = (productStatusCounts[s] || 0) + status[s];
        });
      });

      const productStatusDataFormatted = Object.keys(productStatusCounts).map(
        (key) => ({
          name: key,
          value: productStatusCounts[key],
        })
      );

      setProductStatusData(productStatusDataFormatted);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching dashboard data", error);
      toast.error("Failed to fetch dashboard data.");
      setLoading(false);
    }
  };

  // Colors for Pie Chart
  const COLORS = [
    "#0088FE",
    "#FF8042",
    "#FFBB28",
    "#00C49F",
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
  ];

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin h-10 w-10 text-blue-500" />
        <span className="ml-2 text-gray-700">Loading Dashboard...</span>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <ToastContainer />

      <h1 className="text-3xl font-bold mb-6 text-gray-800">Admin Dashboard</h1>

      {/* KPI Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <KPI
          title="Total Brands"
          value={brandsCount}
          icon={<FaBuilding className="h-6 w-6 text-blue-500" />}
          color="border-blue-500"
        />
        <KPI
          title="Total Suppliers"
          value={suppliersCount}
          icon={<FaTruck className="h-6 w-6 text-green-500" />}
          color="border-green-500"
        />
        <KPI
          title="Total Products"
          value={productsCount}
          icon={<FaBox className="h-6 w-6 text-indigo-500" />}
          color="border-indigo-500"
        />
        <KPI
          title="Completed Products (%)"
          value={`${completedProductsPercentage}%`}
          icon={<FaCheckCircle className="h-6 w-6 text-indigo-500" />}
          color="border-indigo-500"
        />
        <KPI
          title="Unactive Suppliers"
          value={invalidSuppliersCount}
          icon={<FaUserTimes className="h-6 w-6 text-red-500" />}
          color="border-red-500"
        />
        {/* Add more KPIs as needed */}
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Products Over Time Line Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800">
              Products Over Time
            </h2>
            <FaChartLine className="h-6 w-6 text-blue-500" />
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={productsOverTime}>
              <XAxis dataKey="month" stroke="#8884d8" />
              <YAxis stroke="#8884d8" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="products"
                stroke="#8884d8"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* Supplier Status Distribution Pie Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800">
              Supplier Status Distribution
            </h2>
            <FaChartPie className="h-6 w-6 text-green-500" />
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={supplierStatus}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {supplierStatus.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Supplier Distribution by Country Bar Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800">
              Supplier Distribution by Country
            </h2>
            <FaChartBar className="h-6 w-6 text-purple-500" />
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={supplierDistribution}>
              <XAxis dataKey="country" stroke="#82ca9d" />
              <YAxis stroke="#82ca9d" />
              <Tooltip />
              <Legend />
              <Bar dataKey="suppliers" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Product Data Status by Type Pie Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800">
              Product Data Status by Type
            </h2>
            <FaChartPie className="h-6 w-6 text-pink-500" />
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={productStatusData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {productStatusData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
