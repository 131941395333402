// FormBuilder.js
import React, { useState, useCallback, useMemo } from "react";
import { Button, Modal, message, Form, Select, Space, Alert } from "antd";
import {
  CloseCircleFilled,
  ExclamationCircleFilled,
  CheckCircleFilled,
  InfoCircleFilled,
  MehOutlined,
} from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { Save, DraftingCompass, CheckCircle, XCircle } from "lucide-react";
import FormSection from "../form/FormSection";
import FormFields from "../form/FormFields";
import { useFormData } from "../../../hooks/useFormData";
import { useFormValidation } from "../../../hooks/useFormValidation";
import {
  evaluateTemplate,
  transformExternalValidationResults,
} from "../../../utils/schemaUtils";
import { validateData } from "../../../services/validationService";
import FormActionButtons from "../form/FormActionButtons";

const FormBuilder = ({
  schema = null,
  uiSchema = null,
  initialData = null,
  onChange = () => {},
  onDraft = null,
  onSubmit = null,
  onApprove = null,
  onReject = null,
  mode = "edit",
  topLevelInfo = [],
  comments = "",
  role = "SUPPLIER",
  form_type = "",
}) => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [showAssociationModal, setShowAssociationModal] = useState(false);
  const [pendingSection, setPendingSection] = useState(null);
  const [pendingData, setPendingData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [rejectComments, setRejectComments] = useState("");
  const [form] = Form.useForm();
  const sectionOrder = uiSchema["ui:order"] || Object.keys(schema.properties);

  const [rejectionComment, setRejectionComment] = useState(() => {
    return comments ? comments : "";
  });

  const getParentSectionInfo = useCallback(
    (sectionKey) => {
      const sectionSchema = schema.properties[sectionKey];
      if (!sectionSchema?.items?.dependencies?.parent) return null;

      const { parentSection, labelTemplate } =
        sectionSchema.items.dependencies.parent;
      return {
        parentSection,
        labelTemplate,
      };
    },
    [schema]
  );

  const getModalTitle = useCallback(
    (sectionKey) => {
      const parentInfo = getParentSectionInfo(sectionKey);
      if (!parentInfo) return "";

      const parentSchema = schema.properties[parentInfo.parentSection];
      return `Select ${parentSchema.title || parentInfo.parentSection}`;
    },
    [schema, getParentSectionInfo]
  );
  const {
    formData,
    relationships,
    handleFieldChange,
    handleAddSection,
    handleRemoveSection,
    handleDuplicateSection,
    setInitialFormData,
  } = useFormData(schema, initialData, onChange, topLevelInfo);

  const handleSectionAdd = (sectionKey) => {
    const result = handleAddSection(sectionKey);
    if (result?.needsAssociation) {
      setPendingSection(result.sectionKey);
      setPendingData(result.data);
      setShowAssociationModal(true);
      form.resetFields();
    }
  };

  const handleConfirmAssociation = (parentId) => {
    if (pendingSection && pendingData) {
      handleAddSection(pendingSection, parentId, pendingData);
      setShowAssociationModal(false);
      setPendingSection(null);
      setPendingData(null);
      form.resetFields();
    }
  };

  const { validationErrors, validateForm, hasErrors, setValidationErrors } =
    useFormValidation(schema);

  /*useEffect(() => {
    if (initialData) {
      setInitialFormData(initialData);
    }
  }, [initialData, setInitialFormData]);*/

  const handleSectionToggle = useCallback((sectionKey, index) => {
    const sectionId = index !== null ? `${sectionKey}-${index}` : sectionKey;
    setExpandedSection((current) => (current === sectionId ? null : sectionId));
  }, []);

  const handleSubmit = async (
    isDraft = false,
    isApproved = false,
    isRejected = false
  ) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const finalFormData = structuredClone(formData);
    // or JSON.parse(JSON.stringify(formData))

    // Remove the top-level "data" key:
    delete finalFormData.data;
    try {
      if (isDraft) {
        // Draft logic
        await onDraft?.(finalFormData);
        setValidationErrors({});
        //message.success("Draft saved successfully");
      } else if (isApproved) {
        // Approval logic
        await onApprove?.(finalFormData);
        setValidationErrors({});
        //message.success("Form approved successfully");
      } else if (isRejected) {
        // Show the reject modal
        setIsRejectModalVisible(true);
      } else {
        const validationResponse = await handleVerificationOnly(false);

        // Show different messages based on severity
        const hasErrors = validationResponse.validationResults.some(
          (result) => result.severity === "error"
        );

        if (hasErrors) {
          Modal.confirm({
            title: "Validation Error",
            icon: <CloseCircleFilled style={{ color: "red" }} />,
            content: (
              <div>
                <p>
                  Some data you’ve submitted are not valid. Please check the
                  error messages before sending the form.
                </p>
                <br />
                <p>
                  <strong>NB:</strong> In some cases the errors are due to some
                  limitations of our system, in which case you can force its
                  submission, however we invite you to check the data carefully
                  before forcing the submission. If any questions persist, our
                  teams will contact you to verify some elements.
                </p>
              </div>
            ),
            okText: "Submit Without Validation",
            cancelText: "Cancel",
            onOk: handleSubmitWithoutValidation,
          });
        } else if (
          validationResponse.validationResults.some(
            (result) => result.severity === "warning"
          )
        ) {
          Modal.confirm({
            title: "Validation Warning",
            icon: <ExclamationCircleFilled style={{ color: "orange" }} />,
            content: (
              <div>
                <p>
                  Some data you’ve submitted raise some warnings in our system.
                  Please verify the messages before sending the form.
                </p>
                <br />
                <p>
                  <strong>NB:</strong> The warnings we display are
                  inconsistencies we have identified, they may not be relevant
                  for your products. However we invite you to check the data
                  carefully before the submission. If any questions persist, our
                  team will contact you to verify some elements.
                </p>
              </div>
            ),
            okText: "Submit Without Validation",
            cancelText: "Back to Form",
            onOk: handleSubmitWithoutValidation,
          });
        } else {
          //await onSubmit?.(finalFormData);
          // If validation passes, proceed with submission dispaly success modal and ask for confirmation to submit
          Modal.confirm({
            title: "Validation Success",
            icon: <CheckCircleFilled style={{ color: "#52c41a" }} />,
            content: (
              <div>
                <p>
                  The data you have completed does not raise errors from our
                  system. You can submit or keep working on it.
                </p>
                <br />
                <p>
                  <strong>NB:</strong> once submitted, you won’t be able to
                  modify the data. If you need to adjust some data, you will
                  need to contact our support directly.
                </p>
              </div>
            ),
            okText: "Submit",
            cancelText: "Back to Form",
            onOk: async () => {
              try {
                await onSubmit?.(finalFormData);
                //message.success("Form submitted successfully");
              } catch (error) {
                //console.error("Error submitting form:", error);
                message.error("Error saving form. Please try again.");
              } finally {
                setIsSubmitting(false);
              }
            },
          });
        }
      }
    } catch (error) {
      //console.error("Error submitting form:", error);
      message.error("Error saving form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle function for all validation errors with external service and without submission
  const handleVerificationOnly = async (showMessage = true) => {
    const finalFormData = structuredClone(formData);

    // Remove the top-level "data" key:
    delete finalFormData.data;

    const { errors, totalMissingFields } = validateForm(
      finalFormData,
      relationships
    );
    setValidationErrors(errors);

    if (hasErrors({ totalMissingFields })) {
      message.error(`Please fill in all required fields`);
      setIsSubmitting(false);
      return;
    }

    // External validation
    try {
      const validationResponse = await validateDataWithExternalService(
        finalFormData
      );
      if (!validationResponse.isValid) {
        console.log("Validation response:", validationResponse);
        const transformedErrors = transformExternalValidationResults(
          validationResponse.validationResults
        );

        setValidationErrors(transformedErrors);

        if (showMessage) {
          const hasErrors = validationResponse.validationResults.some(
            (result) => result.severity === "error"
          );

          if (hasErrors) {
            message.error(
              "The data contains errors. Please check the error messages before sending the form."
            );
          } else if (
            validationResponse.validationResults.some(
              (result) => result.severity === "warning"
            )
          ) {
            message.warning(
              "The data contains warnings. Please check the warning messages before sending the form."
            );
          }
        }

        return validationResponse;
      } else {
        if (showMessage) {
          message.success("Data is valid !");
        }
        return validationResponse;
      }
    } catch (validationError) {
      //message.error("Error during data validation. Please try again.");
      const modalProps = {
        title: "Service Unavailable",
        icon: <MehOutlined style={{ color: "red" }} />,
        content:
          "Our validation service is currently unavailable. " +
          (showMessage
            ? "Please try again later."
            : "Do you want to submit the form without validation?"),
      };

      if (showMessage) {
        Modal.info({ ...modalProps, okText: "Close" });
      } else {
        Modal.confirm({
          ...modalProps,
          okText: "Submit Without Validation",
          cancelText: "Cancel",
          onOk: handleSubmitWithoutValidation,
        });
      }
      return;
    }
  };

  const validateDataWithExternalService = async (data) => {
    try {
      return await validateData(data, form_type);
    } catch (error) {
      console.error("Error validating data:", error);
      throw new Error("Failed to validate data");
    }
  };

  const handleSubmitWithoutValidation = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      await onSubmit?.(formData, true);
      //message.success("Form submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Error saving form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const getAvailableParents = useCallback(
    (sectionKey) => {
      const sectionSchema = schema.properties[sectionKey];
      if (!sectionSchema?.items?.dependencies?.parent) return [];

      const { parentSection, labelTemplate } =
        sectionSchema.items.dependencies.parent;
      return (formData[parentSection] || []).map((parent, index) => ({
        value: `${parentSection}-${index}`,
        label: evaluateTemplate(labelTemplate, { ...parent, index: index + 1 }),
      }));
    },
    [formData, schema]
  );

  const renderSections = () => {
    // Check if schema has direct fields (non-sectional form)
    const hasDirectFields = Object.values(schema.properties).some(
      (prop) => prop.type !== "object" && prop.type !== "array"
    );

    if (hasDirectFields) {
      return (
        <div className="p-4">
          <FormFields
            sectionSchema={schema}
            sectionKey=""
            formData={formData}
            validationErrors={validationErrors}
            onChange={handleFieldChange}
            uiSchema={uiSchema}
          />
        </div>
      );
    }
    return sectionOrder.map((sectionKey) => {
      const sectionSchema = schema.properties[sectionKey];
      const sectionUiSchema = uiSchema[sectionKey];
      if (!sectionSchema) return null; // Handle case where ui:order has a key not in schema

      const isArray = sectionSchema.type === "array";

      if (isArray) {
        const itemUiSchema = uiSchema[sectionKey]?.items || {};
        return (
          <div key={sectionKey}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <h3 style={{ margin: 0 }}>
                {(sectionSchema.title || sectionKey)
                  .toUpperCase()
                  .replace(/S$/, "(S)")}
              </h3>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => handleSectionAdd(sectionKey)}
              >
                {uiSchema[sectionKey]?.["ui:addLabel"] ||
                  `Add ${sectionSchema.title || sectionKey}`}
              </Button>
            </div>
            {(formData[sectionKey] || []).map((_, index) => {
              const data = formData[sectionKey][index] || {};
              const parentId = relationships[`${sectionKey}-${index}`];
              const titleTemplate =
                itemUiSchema["ui:sectionConfig"]?.titleTemplate ||
                `${sectionSchema.title || sectionKey} #${index + 1}`;
              const title = evaluateTemplate(titleTemplate, {
                ...data,
                index: index + 1,
              });

              // Determine if the section has errors
              const sectionErrors =
                validationErrors[`${sectionKey}-${index}`] || {};
              const hasSectionError = Object.values(sectionErrors).some(
                (error) => error.severity === "error"
              );

              return (
                <FormSection
                  key={`${sectionKey}-${index}`}
                  title={title || `${sectionKey} #${index + 1}`}
                  titleTemplate={titleTemplate}
                  sectionData={data}
                  index={index}
                  isExpanded={expandedSection === `${sectionKey}-${index}`}
                  onToggle={() => handleSectionToggle(sectionKey, index)}
                  onDuplicate={() => handleDuplicateSection(sectionKey, index)}
                  onDelete={() => handleRemoveSection(sectionKey, index)}
                  canDelete={
                    !sectionSchema.minItems ||
                    formData[sectionKey].length > sectionSchema.minItems
                  }
                  hasError={hasSectionError}
                  showActions={true}
                  validationStatus={
                    validationErrors[`${sectionKey}-${index}`]?._section
                      ?.severity ||
                    Object.values(
                      validationErrors[`${sectionKey}-${index}`] || {}
                    ).some((error) => error.severity === "error")
                      ? "error"
                      : Object.values(
                          validationErrors[`${sectionKey}-${index}`] || {}
                        ).some((error) => error.severity === "warning")
                      ? "warning"
                      : undefined
                  }
                  relationships={relationships}
                  sectionKey={sectionKey}
                  getAvailableParents={getAvailableParents}
                >
                  <FormFields
                    sectionSchema={sectionSchema.items}
                    sectionKey={sectionKey}
                    index={index}
                    formData={formData}
                    validationErrors={validationErrors}
                    onChange={handleFieldChange}
                    relationships={relationships}
                    getAvailableParents={getAvailableParents}
                    uiSchema={itemUiSchema}
                    topLevelInfo={topLevelInfo}
                  />
                </FormSection>
              );
            })}
          </div>
        );
      }

      return (
        <FormSection
          key={sectionKey}
          title={sectionSchema.title || sectionKey}
          titleTemplate={sectionUiSchema?.["ui:sectionConfig"]?.titleTemplate}
          sectionData={formData[sectionKey]}
          isExpanded={expandedSection === sectionKey}
          onToggle={() => handleSectionToggle(sectionKey, null)}
          hasError={!!validationErrors[sectionKey]}
          showActions={false}
          validationStatus={
            // TODO: Simplify this logic
            validationErrors[sectionKey]?._section?.severity ||
            Object.values(validationErrors[sectionKey] || {}).some(
              (error) => error.severity === "error"
            )
              ? "error"
              : Object.values(validationErrors[sectionKey] || {}).some(
                  (error) => error.severity === "warning"
                )
              ? "warning"
              : undefined
          }
          relationships={relationships}
          sectionKey={sectionKey}
        >
          <FormFields
            sectionSchema={sectionSchema}
            sectionKey={sectionKey}
            formData={formData}
            validationErrors={validationErrors}
            onChange={handleFieldChange}
            uiSchema={sectionUiSchema}
            topLevelInfo={topLevelInfo}
          />
        </FormSection>
      );
    });
  };

  return (
    <div className="form-builder p-4">
      <>
        {rejectionComment && (
          <div className="rejection-comment p-4">
            <Alert
              message="Form Refused"
              description={rejectionComment}
              type="error"
              showIcon
            />
          </div>
        )}
        {renderSections()}
        <FormActionButtons
          role={role}
          isSubmitting={isSubmitting}
          onDraft={handleSubmit}
          onVerify={handleVerificationOnly}
          onSubmit={handleSubmit}
          onReject={(formData, comments) => handleSubmit(false, false, true)}
          onApprove={() => handleSubmit(false, true)}
          mode={mode}
        />

        <Modal
          title="Reject Confirmation"
          icon={<InfoCircleFilled style={{ color: "red" }} />}
          visible={isRejectModalVisible}
          onCancel={() => {
            setIsRejectModalVisible(false);
            setRejectComments("");
            setIsSubmitting(false);
          }}
          onOk={async () => {
            try {
              await onReject?.(formData, rejectComments);
              setValidationErrors({});
              //message.success("Form rejected successfully");
            } catch (error) {
              console.error("Error rejecting form:", error);
              message.error("Error rejecting form. Please try again.");
            } finally {
              setIsSubmitting(false);
              setIsRejectModalVisible(false);
              setRejectComments("");
            }
          }}
          okText="Reject"
          cancelText="Cancel"
        >
          <div>
            <textarea
              placeholder="Add comments (optional)"
              rows="4"
              className="w-full mt-2 p-2 border rounded-md"
              value={rejectComments}
              onChange={(e) => setRejectComments(e.target.value)}
            />
          </div>
        </Modal>

        <Modal
          title={getModalTitle(pendingSection)}
          open={showAssociationModal}
          onCancel={() => {
            setShowAssociationModal(false);
            setPendingSection(null);
            setPendingData(null);
            form.resetFields();
          }}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              handleConfirmAssociation(values.partId);
            }}
          >
            <Form.Item
              name="partId"
              label={`Select ${
                getParentSectionInfo(pendingSection)?.parentSection || ""
              }`}
              rules={[{ required: true, message: "Please make a selection" }]}
            >
              <Select
                options={getAvailableParents(pendingSection)}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item>
              <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => {
                    setShowAssociationModal(false);
                    setPendingSection(null);
                    setPendingData(null);
                    form.resetFields();
                  }}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Confirm
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </>
    </div>
  );
};

export default FormBuilder;
