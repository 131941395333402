import React, { useEffect, useState } from "react";
import { getBrand, getBrandUsers, getBrandSuppliers, getBrandProducts } from "../../services/brandService";
import { useParams, useNavigate } from "react-router-dom";
import InfoCard from "../../components/ui/cards/InfoCard";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import StatusBadge from "../../components/ui/badges/StatusBadge";
import { message, Button, Flex, Spin } from "antd";

const BrandDetail = () => {
  const { id } = useParams();
  const [brand, setBrand] = useState(null);
  const navigate = useNavigate();
  const [UsersData, setUsersData] = useState([]);
  const [ProductsData, setProductsData] = useState([]);
  const [SuppliersData, setSuppliersData] = useState([]);

  const [productPage, setProductPage] = useState(1);
  const [productPageSize, setProductPageSize] = useState(10);
  const [productTotalCount, setProductTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");


  useEffect(() => {
    fetchBrandDetails();
  }, [id]);

  useEffect(() => {
    fetchBrandProducts();
  }, [id, productPage, productPageSize, searchValue]);

  const fetchBrandDetails = async () => {
    try {
      await Promise.all([
        fetchBrandData(),
        fetchBrandUsers(),
        fetchBrandSuppliers(),
        fetchBrandProducts(),
      ]);
    } catch (error) {
      console.error("Error fetching brand details", error);
      message.error("Error fetching brand details");
    }
  };

  const fetchBrandData = () => {
    getBrand(id)
      .then((response) => {
        setBrand(response.data);
      })
      .catch((error) => {
        console.error("Error fetching brand details", error);
      });
  };

  const fetchBrandUsers = () => {
    getBrandUsers(id)
      .then((response) => {
        setUsersData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching brand users", error);
      });
  };

  const fetchBrandSuppliers = () => {
    getBrandSuppliers(id)
      .then((response) => {
        setSuppliersData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching brand suppliers", error);
        message.error("Error fetching brand suppliers");
      });
  };

  const fetchBrandProducts = async () => {
    try {
      const response = await getBrandProducts(id, {
        page: productPage,
        per_page: productPageSize,
        //sort_by: productSortBy,
        //sort_dir: productSortDir,
        search: searchValue,
      });

      setProductsData(response.data.data.data);

      if (response.data.meta && response.data.meta.count) {
        setProductTotalCount(response.data.meta.count);
      }
    } catch (error) {
      console.error("Error fetching brand products", error);
      message.error("Error fetching brand products");
    }
  };

  if (!brand) {
    return (
      <Flex justify="center" align="middle" style={{ height: "100vh" }}>
        <Spin size="large" />
      </Flex>
    );
  }

  // Define fields for InfoCard
  const brandFields = [
    { name: "address", label: "Address" },
    { name: "country", label: "Country" },
    {
      name: "license_types",
      label: "License Types",
      render: (value) => value.join(", "),
    },
    {
      name: "created_at",
      label: "Created At",
      render: (value) => new Date(value).toLocaleDateString(),
    },
    {
      name: "updated_at",
      label: "Updated At",
      render: (value) => new Date(value).toLocaleDateString(),
    },
  ];

  // Prepare suppliers data for DataGrid
  const suppliers = SuppliersData.map((supplier) => ({
    id: supplier.id,
    name: supplier.attributes.name,
    tva_number: supplier.attributes.tva_number,
    country: supplier.attributes.country,
  }));

  // Prepare products data for DataGrid
  const products = ProductsData.map((product) => ({
    id: product.id,
    name: product.attributes.name,
    status: product.attributes.status,
    reference_brand: product.attributes.reference_brand,
    reference_supplier: product.attributes.reference_supplier,
    supplier: SuppliersData.find(
      (supplier) => supplier.id === product.relationships.supplier.data.id
    )?.attributes?.name,
  }));

  // Prepare users data for DataGrid
  const users = UsersData.map((user) => ({
    id: user.id,
    name: user.attributes.name,
    email: user.attributes.email,
    role: user.attributes.role,
    status: user.attributes.invitation_status,
  }));

  // Define columns for suppliers
  const columnsSupplier = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "TVA Number",
      dataIndex: "tva_number",
      key: "tva_number",
      width: 200,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 200,
    },
  ];

  const handleSupplierClick = (supplier) => {
    navigate(`/admin/suppliers/${supplier.id}`);
  };

  // Define columns for products
  const columnsProduct = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (text) => <StatusBadge status={text} />,
    },
    {
      title: "Reference Brand",
      dataIndex: "reference_brand",
      key: "reference_brand",
      width: 200,
    },
    {
      title: "Reference Supplier",
      dataIndex: "reference_supplier",
      key: "reference_supplier",
      width: 200,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: 200,
    },
  ];

  const handleProductClick = (product) => {
    navigate(`/admin/manage-products/${product.id}`);
  };

  const handleSearch = (newSearchValue) => {
    setProductPage(1);
    setSearchValue(newSearchValue);
  };

  // Define columns for users
  const columnsUser = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (text) => <StatusBadge status={text} />,
    },
  ];

  return (
    <div className="p-6">
      <Button onClick={() => navigate(-1)} className="mb-4">
        Back
      </Button>

      {/* Brand Information */}
      <InfoCard
        title={brand.data.attributes.name}
        fields={brandFields}
        data={brand.data.attributes}
      />

      {/* Suppliers Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Suppliers</h3>
        <DataGrid
          columns={columnsSupplier}
          data={suppliers}
          onRowClick={handleSupplierClick}
        />
      </div>

      {/* Users Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Users</h3>
        <DataGrid columns={columnsUser} data={users} />
      </div>

      {/* Products Section */}
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-4">Products</h3>
        <DataGrid
          columns={columnsProduct}
          data={products}
          onRowClick={handleProductClick}
          serverSide={true}
          currentPage={productPage}
          pageSize={productPageSize}
          totalItems={productTotalCount}
          onChangePage={(newPage) => setProductPage(newPage)}
          onChangePageSize={(newPageSize) => setProductPageSize(newPageSize)}
          searchValue={searchValue}
          onSearch={handleSearch}
        />
      </div>
    </div>
  );
};

export default BrandDetail;
