import api from "./api";

/*export const exportDataPEF = (exportPayload) => {
  return api
    .post("/pef_exports", exportPayload, { responseType: "blob" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.response?.data?.error || "Failed to export data");
    });
};*/

export const exportProductData = (productDataId) => {
  return api.get(`/api/v1/product_data/${productDataId}/export`, {
    responseType: 'blob'
  });
};

export const bulkExportProductData = (productDataIds) => {
  return api.post('/product_data/bulk_export', {
    ids: productDataIds
  }, {
    responseType: 'blob'
  });
};