import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Form, message, Button } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { FaPlus } from "react-icons/fa";

import { useManageProducts } from "../../hooks/products/useManageProducts";
import { getProductColumns } from "../../utils/products/productColumns";
import { getProductKpis } from "../../utils/products/productKpis";

import DataGrid from "../../components/ui/datagrid/DataGrid";
import KPIDashboard from "../../components/ui/kpis/KPIDashboard";
import ImportEntityModal from "../../components/ui/modal/ImportEntityModal";
import ExportEntityModal from "../../components/ui/modal/ExportEntityModal";
import EntityForm from "../../components/ui/form/EntityForm";

import {
  createProduct,
  updateProduct,
  deleteProduct,
  importProducts,
} from "../../services/productService";
import { bulkExportProductData } from "../../services/exportService";

import { Modal } from "antd";

const ManageProducts = () => {
  // --- Hook: fetch & hold product data
  const {
    products,
    brands,
    suppliers,
    //dataTypes,
    loading,
    totalItems,

    // pagination & filters
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
    searchValue,
    setSearchValue,

    // fetch & refresh
    fetchProducts,
    filters,
    setFilters,
    metaKpis,
  } = useManageProducts();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  // UI state
  const [headers, setHeaders] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [filterLicenses, setFilterLicenses] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const [selectedBrandId, setSelectedBrandId] = useState("");

  // On first load of products, set headers if needed:
  useEffect(() => {
    if (products.length) {
      setHeaders(Object.keys(products[0].attributes));
    }
  }, [products]);

  // Filter logic for brand & suppliers
  useEffect(() => {
    if (selectedBrandId) {
      const suppliersForBrand = suppliers.filter((s) =>
        s.attributes.brands.map((b) => b.id).includes(selectedBrandId)
      );
      setFilteredSuppliers(suppliersForBrand);

      const selectedBrand = brands.find((b) => b.id === selectedBrandId);
      if (selectedBrand) {
        const licensesForBrand =
          selectedBrand.attributes.available_form_templates;

        setFilterLicenses(licensesForBrand);
      } else {
        setFilterLicenses([]);
      }
    } else {
      setFilteredSuppliers([]);
      setFilterLicenses([]);
    }
  }, [selectedBrandId, suppliers, brands]);

  // Prepare form fields for EntityForm
  const formFields = useMemo(() => {
    return [
      {
        label: "Brand",
        name: "brand_id",
        type: "select",
        required: true,
        options: brands.map((brand) => ({
          value: brand.id,
          label: brand.attributes.name,
        })),
      },
      {
        label: "Supplier",
        name: "supplier_id",
        type: "select",
        required: true,
        options: filteredSuppliers.map((supplier) => ({
          value: supplier.id,
          label: supplier.attributes.name,
        })),
      },
      { label: "Product Name", name: "name", type: "text", required: true },
      {
        label: "Data type",
        name: "selected_licenses",
        type: "multi-select",
        required: true,
        options: filterLicenses.map((license) => ({
          value: license.code,
          label: license.name,
        })),
      },
      {
        label: "Status",
        name: "status",
        type: "select",
        required: true,
        options: [
          { value: "draft", label: "Draft" },
          { value: "active", label: "Active" },
          { value: "archived", label: "Archived" },
        ],
      },
      {
        label: "Reference Brand",
        name: "reference_brand",
        type: "text",
        required: false,
      },
      {
        label: "Reference Supplier",
        name: "reference_supplier",
        type: "text",
        required: true,
      },
      {
        label: "EAN Code(s)",
        name: "ean_codes",
        type: "array",
        required: false,
        children: [
          {
            type: "text",
            name: "ean_code",
            label: "EAN Code",
            required: true,
            rules: [
              {
                required: true,
                message: "Please enter an EAN code or remove the field",
              },
              { pattern: /^\d+$/, message: "EAN code must be numeric" },
            ],
          },
        ],
      },
    ];
  }, [brands, filteredSuppliers, filterLicenses]);

  // ====================
  // CRUD Handlers
  // ====================

  const handleFinish = (values) => {
    if (editingProduct) {
      // Update
      updateProduct(editingProduct.id, values)
        .then(() => {
          message.success("Product updated successfully");
          resetForm();
          fetchProducts();
        })
        .catch((error) => {
          message.error(error?.response?.data?.message || "Update failed");
        });
    } else {
      // Create
      createProduct(values.brand_id, values)
        .then(() => {
          message.success("Product created successfully");
          resetForm();
          fetchProducts();
        })
        .catch((error) => {
          message.error(error?.response?.data?.message || "Create failed");
        });
    }
  };

  const handleEdit = useCallback(
    (product) => {
      setEditingProduct(product);
      form.setFieldsValue({
        id: product.id,
        brand_id: product.relationships.brand.data.id,
        supplier_id: product.relationships.supplier.data.id,
        name: product.attributes.name,
        selected_licenses: product.attributes.selected_licenses || [],
        status: product.attributes.status,
        reference_brand: product.attributes.reference_brand,
        reference_supplier: product.attributes.reference_supplier,
        ean_codes: product.attributes.ean_codes || [],
      });
      setSelectedBrandId(product.relationships.brand.data.id);
      setIsModalOpen(true);
    },
    [form]
  );

  const handleDelete = useCallback(
    (productId) => {
      Modal.confirm({
        title: "Are you sure you want to delete this product?",
        onOk: () => {
          deleteProduct(productId)
            .then(() => {
              message.success("Product deleted successfully");
              fetchProducts();
            })
            .catch((error) => {
              message.error(error?.response?.data?.message || "Delete failed");
            });
        },
      });
    },
    [fetchProducts]
  );

  const handleViewDetails = useCallback(
    (productId) => {
      navigate(`${window.location.pathname}/${productId}`);
    },
    [navigate]
  );

  // Reset & close
  const resetForm = () => {
    form.resetFields();
    setEditingProduct(null);
    setSelectedBrandId("");
    setIsModalOpen(false);
    setIsImportModalOpen(false);
  };

  // ====================
  // Export Handler
  // ====================
  function generateFilename(filters) {
    if (filters.brandIds?.length === 1) {
      const brand = brands.find((b) => b.id === filters.brandIds[0]);
      if (brand) {
        const brandName = brand.attributes.name;
        const dateStr = new Date().toISOString().split("T")[0];
        return `${brandName}_export_${dateStr}.csv`;
      }
    }
    return `export_${Date.now()}.csv`;
  }

  const handleExportData = (filters) => {
    return new Promise((resolve, reject) => {
      try {
        // List of product data IDs to export
        let productDataIds = filters.productDataIds || [];

        if (productDataIds.length === 0) {
          message.info("No data found for export");
          return;
        }

        const suggestedFilename = generateFilename(filters);

        bulkExportProductData(productDataIds)
          .then((response) => {
            // response.data is the Blob if responseType: 'blob' is set
            const blob = response.data;
            resolve({ blob, filename: suggestedFilename });
          })
          .catch((err) => reject(err));
      } catch (err) {
        reject(err);
      }
    });
  };

  // ====================
  // DataGrid
  // ====================
  const productColumns = useMemo(() => {
    // call getProductColumns
    return getProductColumns({
      handleEdit,
      handleDelete,
      handleViewDetails,
      products,
      //productDataMap,
      brands,
      suppliers,
    });
  }, [
    handleEdit,
    handleDelete,
    handleViewDetails,
    products,
    //productDataMap,
    brands,
    suppliers,
  ]);

  // Transform "products" for the DataGrid
  const mappedProducts = useMemo(() => {
    return products.map((product) => {
      const productId = product.id;
      //const statuses = productDataMap[productId]?.map((pd) => pd.status) || [];
      //const uniqueStatuses = [...new Set(statuses)];

      return {
        originalProduct: product, // for direct reference
        id: productId,
        name: product.attributes.name,
        brand_name: product.attributes.brand_name,
        supplier_name: product.attributes.supplier_name,
        status:
          product.attributes.status.charAt(0).toUpperCase() +
          product.attributes.status.slice(1),
        //product_data_statuses: uniqueStatuses,
        published_at: product.attributes.created_at
          ? new Date(product.attributes.created_at).toLocaleString()
          : "N/A",
        product_data_statuses: product.attributes.product_data_status,
      };
    });
  }, [products]);

  // ====================
  // KPIs
  // ====================
  const productKPIs = useMemo(
    () => getProductKpis({ metaKpis }),
    [products, metaKpis, totalItems]
  );

  // ====================
  // handleValuesChange
  // ====================
  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues.brand_id !== undefined) {
      setSelectedBrandId(changedValues.brand_id);
      // Reset fields in form
      form.setFieldsValue({
        supplier_id: undefined,
        selected_licenses: undefined,
      });
    }
  };

  const handleSort = (field, direction) => {
    if (loading) return; // prevent multiple calls while loading
    setSortBy(field);
    setSortDirection(direction);
  };

  const handleSearchChange = (value) => {
    setCurrentPage(1);
    setSearchValue(value);
  };

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Manage Products</h2>
        <div className="flex space-x-4">
          <Button
            onClick={() => setIsModalOpen(true)}
            type="primary"
            icon={<FaPlus />}
          >
            Add New Product
          </Button>

          <Button
            onClick={() => setIsImportModalOpen(true)}
            type="default"
            icon={<UploadOutlined />}
          >
            Import Products (CSV)
          </Button>

          <Button
            onClick={() => setIsExportModalOpen(true)}
            type="default"
            icon={<DownloadOutlined />}
          >
            Export Data
          </Button>
        </div>
      </div>

      {/* KPI Dashboard */}
      <KPIDashboard kpis={productKPIs} />

      {loading ? (
        <div className="flex justify-center items-center text-gray-500">
          <ClipLoader size={50} />
        </div>
      ) : (
        <DataGrid
          columns={productColumns}
          data={mappedProducts}
          loading={loading}
          currentPage={currentPage}
          pageSize={pageSize}
          totalItems={totalItems}
          onChangePage={(page) => setCurrentPage(page)}
          onChangePageSize={(size) => setPageSize(size)}
          //onSearch={setSearchTerm}
          onChangeSort={handleSort}
          serverSide={true}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSearch={handleSearchChange}
          searchValue={searchValue}
          onChangeFilter={(tableFilters) => {
            setFilters(tableFilters);
          }}
          tableFilters={filters}
        />
      )}

      {/* Create/Edit Modal */}
      <EntityForm
        onSubmit={handleFinish}
        editingEntity={editingProduct}
        isOpen={isModalOpen}
        onClose={resetForm}
        fields={formFields}
        entityName="Product"
        form={form}
        onValuesChange={handleValuesChange}
      />

      {/* Import Modal */}
      <ImportEntityModal
        visible={isImportModalOpen}
        onClose={resetForm}
        onImportSuccess={fetchProducts}
        importServiceFunction={importProducts}
        entityName="Products"
        headers={headers}
      />

      {/* Export Modal */}
      <ExportEntityModal
        visible={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        brands={brands}
        suppliers={suppliers}
        onExport={handleExportData}
      />
    </div>
  );
};

export default ManageProducts;
