import React, { useEffect, useState } from "react";
import { Alert, Button } from "antd";
import {
  FaBox,
  FaCheckCircle,
  FaHourglassHalf,
  FaChartLine,
  FaSpinner,
  FaInfoCircle,
  FaExclamationTriangle,
  FaPaperPlane,
} from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";

import ProductDataList from "./ProductDataList";
import KPI from "../../components/ui/kpis/KPI";
import OnboardingSection from "./OnboardingSection";
import { useSupplierProducts } from "../../hooks/products/useSupplierProducts";

const SupplierDashboard = () => {
  const {
    products,
    loading,
    fetchProducts, // Use the fetch function from the hook
    filters,
    setFilters,
    currentPage,
    pageSize,
    totalItems,
    setCurrentPage,
    setPageSize,
    setSortBy,
    setSortDirection,
    sortBy,
    sortDirection,
    metaKpis,
    searchValue,
    setSearchValue,
  } = useSupplierProducts({ flattened: true } );

  const [productDataMap, setProductDataMap] = useState({});
  const [error, setError] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(true);

  // KPI states
  const [kpiData, setKpiData] = useState({
    total: 0,
    pending: 0,
    toComplete: 0,
    refused: 0,
    submitted: 0,
    accepted: 0,
  });

  // Handle onboarding visibility
  useEffect(() => {
    const onboardingHidden = localStorage.getItem("onboardingHidden");
    if (onboardingHidden === "true") {
      setShowOnboarding(false);
    }
  }, []);

  // Process products and included data whenever they change
  useEffect(() => {
    if (products) {
      processKPIData();
    }
  }, [products, pageSize, currentPage, filters, metaKpis]);

  const processKPIData = () => {
    const counts = {
      total_products_data: metaKpis?.total_products_data || 0,
      in_progress: 0,
      to_complete: 0,
      submitted: 0,
      forced_submitted: 0,
      accepted: 0,
      refused: 0,
    };

    const productDataStatus = metaKpis?.product_data_status || {};

    Object.keys(productDataStatus).forEach((key) => {
      const status = productDataStatus[key];
      Object.keys(status).forEach((s) => {
        counts[s] = (counts[s] || 0) + status[s];
      });
    });

    setKpiData({
      total: counts.total_products_data,
      submitted: (counts.submitted + counts.forced_submitted),
      accepted: counts.accepted,
      pending: counts.in_progress,
      toComplete: counts.to_complete,
      refused: counts.refused,
    });
  };

  const hideOnboarding = () => {
    setShowOnboarding(false);
    localStorage.setItem("onboardingHidden", "true");
  };

  const handleSort = (field, direction) => {
    if (loading) return; // prevent multiple calls while loading
    setSortBy(field);
    setSortDirection(direction);
  };


  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin h-10 w-10 text-blue-500" />
        <span className="ml-2 text-gray-700">Loading Dashboard...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 text-center">
        <Alert
          message="Error"
          description="Unable to load the dashboard. Please try again later."
          type="error"
          showIcon
          action={
            <Button size="small" type="primary" onClick={fetchProducts}>
              Retry
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Onboarding toggle button */}
      <div className="flex justify-end mb-4">
        {!showOnboarding && (
          <button
            onClick={() => {
              setShowOnboarding(true);
              localStorage.removeItem("onboardingHidden");
            }}
            className="bg-gray-800 text-white px-4 py-2 rounded-md"
          >
            <FaInfoCircle className="h-4 w-4 inline-block mr-2" />
            Show onboarding details
          </button>
        )}
      </div>

      {/* Onboarding Section */}
      {showOnboarding && <OnboardingSection onHide={hideOnboarding} />}

      <h1 className="text-3xl font-bold mb-6 text-gray-800">Your Dashboard</h1>

      {/* KPI Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <KPI
          title="Total requests"
          value={kpiData.total}
          icon={<FaBox className="h-6 w-6 text-indigo-500" />}
          color="border-indigo-500"
        />
        <KPI
          title="Requests to complete"
          value={kpiData.toComplete}
          icon={<FaHourglassHalf className="h-6 w-6 text-yellow-500" />}
          color="border-yellow-500"
        />
        <KPI
          title="Requests in progress"
          value={kpiData.pending}
          icon={<FaChartLine className="h-6 w-6 text-blue-500" />}
          color="border-blue-200"
        />
        <KPI
          title="Submitted requests"
          value={kpiData.submitted}
          icon={<FaPaperPlane className="h-6 w-6 text-purple-500" />}
          color="border-purple-500"
        />
        <KPI
          title="Refused requests"
          value={kpiData.refused}
          icon={<FaExclamationTriangle className="h-6 w-6 text-red-500" />}
          color="border-red-500"
        />
        <KPI
          title="Accepted requests"
          value={kpiData.accepted}
          icon={<FaCheckCircle className="h-6 w-6 text-green-500" />}
          color="border-green-500"
        />
      </div>

      {/* Product List Section */}
      <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800">
          Product Data Requests
        </h2>
        <ProductDataList
          products={products}
          productDataMap={productDataMap}
          refreshProducts={fetchProducts}
          currentPage={currentPage}
          pageSize={pageSize}
          totalItems={totalItems}
          setCurrentPage={setCurrentPage}
          setPageSize={setPageSize}
          loading={loading}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onChangeSort={handleSort}
          filters={filters}
          setFilters={setFilters}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </div>
    </div>
  );
};

export default SupplierDashboard;
