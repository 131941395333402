import React, { useState, useCallback } from "react";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import StatusBadge from "../../components/ui/badges/StatusBadge";
import FormBuilder from "../../components/ui/datagrid/FormBuilder";
import {
  updateProductData,
  createProductData,
} from "../../services/productService";
import { Button, message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const ProductDataList = ({
  products,
  refreshProducts,
  currentPage,
  pageSize,
  totalItems,
  setCurrentPage,
  setPageSize,
  loading,
  sortBy,
  sortDirection,
  onChangeSort,
  filters,
  setFilters,
  searchValue,
  setSearchValue,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentProductData, setCurrentProductData] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false);

  console.log("currentProductData", currentProductData);
  const columns = [
    {
      title: "Name",
      dataIndex: "product_name",
      key: "product_name",
      sortField: "name",
      sorter: true,
    },
    {
      title: "Reference",
      dataIndex: "reference_supplier",
      key: "reference",
      sortField: "reference_supplier",
      sorter: true,
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      sortField: "brand_name",
      //sorter: true,
    },
    {
      title: "Data Type",
      dataIndex: "product_data_type",
      key: "product_data_type",
    },
    {
      title: "Status",
      dataIndex: "product_data_status",
      key: "product_data_status",
      sortField: "product_data_statuses",
      render: (status) => <StatusBadge status={status || "N/A"} />,
      sorter: true,
      filters: [
        {
          text: "In Progress",
          value: "in_progress",
        },
        {
          text: "To Complete",
          value: "to_complete",
        },
        {
          text: "Refused",
          value: "refused",
        },
      ],
      filteredValue: filters?.product_data_status || null,
    },
    {
      title: "Updated At",
      dataIndex: "product_data_updated_at",
      key: "product_data_updated_at",
      sortField: "updated_at",
      render: (val) => (val ? new Date(val).toLocaleString() : "N/A"),
      sorter: true,
    },
    {
      dataIndex: "actions",
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        const status = record.product_data_status?.toLowerCase();
        let actionLabel = "View Details";
        let isDisabled = false;

        switch (status) {
          case "to_complete":
            actionLabel = "Fill Data";
            break;
          case "refused":
            actionLabel = "Correct Data";
            break;
          case "in_progress":
            actionLabel = "Continue Editing";
            break;
          case "submitted":
          case "accepted":
          case "forced_submitted":
            actionLabel = "View Details";
            break;
          default:
            isDisabled = true;
        }
        return (
          <button
            onClick={() =>
              handleFillData(record.product_id, record.product_data_id)
            }
            disabled={isDisabled}
            className={`px-3 py-1 rounded ${
              isDisabled
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {actionLabel}
          </button>
        );
      },
    },
  ];

  const handleFillData = useCallback(
    (productId, productDataId) => {
      const product = products.find(
        (p) => p.product_id === productId && p.product_data_id === productDataId
      );
      if (!product) {
        message.error("Product not found");
        return;
      }

      const existingData = {
        id: product.product_data_id,
        form_template: product.form_template,
        form_data: product.form_data,
        attributes: {
          status: product.product_data_status,
          comment: product.product_data_comment,
          form_type: product.product_data_type,
        },
      };

     // If key in product contains "product_" remove it
      const modifiedProduct = Object.keys(product).reduce((acc, key) => {
        if (key.includes("product_")) {
          acc[key.replace("product_", "")] = product[key];
        } else {
          acc[key] = product[key];
        }
        return acc;
      }, {});

      setCurrentProduct(modifiedProduct);
      setCurrentProductId(productId);
      setCurrentProductData(existingData);
      setIsModalOpen(true);
    },
    [products]
  );

  const closeModal = useCallback(async () => {
    setIsModalOpen(false);
    setCurrentProductId(null);
    setCurrentProductData(null);
    setCurrentProduct(null);
    setIsFormDirty(false);
  }, [refreshProducts]);

  const handleFormSubmit = async (formData, forced_submit = false) => {
    try {
      if (currentProductData) {
        const actionType = forced_submit ? "force_submit" : "submit";
        await updateProductData(
          currentProductId,
          currentProductData.id,
          formData,
          actionType
        );
        message.success("Form submitted successfully");
        await refreshProducts();
        await closeModal();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Failed to submit product data");
    }
  };

  const handleDraft = async (formData) => {
    try {
      if (currentProductData) {
        await updateProductData(
          currentProductId,
          currentProductData.id,
          formData
        );
        message.success("Draft saved successfully");
        await refreshProducts();
        await closeModal();
      }
    } catch (error) {
      console.error("Error saving draft:", error);
      message.error("Failed to save product data draft");
    }
  };

  return (
    <div className="w-full">
      <DataGrid
        data={products || []}
        columns={columns}
        rowKey="id"
        loading={loading}
        serverSide={true}
        currentPage={currentPage}
        pageSize={pageSize}
        totalItems={totalItems}
        onChangePage={(page) => setCurrentPage(page)}
        onChangePageSize={(size) => setPageSize(size)}
        onChangeSort={onChangeSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onChangeFilter={(filters) => setFilters(filters)}
        onSearch={setSearchValue}
        searchValue={searchValue}
      />

      <Modal
        open={isModalOpen}
        footer={null}
        width="80%"
        maskClosable={!isFormDirty}
        keyboard={!isFormDirty}
        onCancel={() => {
          if (isFormDirty) {
            Modal.confirm({
              title: "Unsaved Changes",
              icon: <ExclamationCircleOutlined />,
              content:
                "You have unsaved changes. Are you sure you want to leave?",
              okText: "Stay",
              cancelText: "Leave",
              onOk: () => {},
              onCancel: async () => {
                await closeModal();
              },
            });
          } else {
            closeModal();
          }
        }}
        destroyOnClose={true}
        style={{ top: 20 }}
        bodyStyle={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">
            {currentProduct?.attributes?.name
              ? `Edit Product: ${currentProduct.attributes.name}`
              : "Add Product Data"}
          </h3>
        </div>
        <FormBuilder
          schema={currentProductData?.form_template?.schema}
          uiSchema={currentProductData?.form_template?.ui_schema}
          initialData={currentProductData?.form_data || {}}
          onSubmit={handleFormSubmit}
          onDraft={handleDraft}
          onChange={() => setIsFormDirty(true)}
          topLevelInfo={{
            data: { attributes: currentProduct },
          }}
          comments={
            currentProductData?.attributes?.status === "refused"
              ? currentProductData?.attributes?.comment
              : null
          }
          form_type={currentProductData?.attributes?.form_type}
        />
      </Modal>
    </div>
  );
};

export default ProductDataList;
